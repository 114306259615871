.invalid::before {
  left: auto;
  position: absolute;
  content: "";
  height: 0;
  width: 0;
  border-left: 6px solid #ed756b;
  border-right: 6px solid transparent;
  right: 10px;
  border-right-color: #ed756b;
  border-left-color: transparent;
  bottom: -4px;
  border-bottom: 6px solid transparent;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  white-space: nowrap;
}
.invalid {
  right: 0;
  left: auto;
  position: absolute;
  color: white !important;
  color: #fff;
  font-size: 11px;
  line-height: 1;
  bottom: calc(100% + 4px);
  background: #ed756b;
  padding: 0.3rem 0.5rem;
  z-index: 1;
  border-radius: 3px;
  white-space: nowrap;
}

.invalid-form-input {
  border: 1px solid #ed756b;
}
